import React, {FC, HTMLAttributes} from 'react'
import {
  IdaxDeliveriesReportDocument,
  IdaxDeliveriesReportDownloadDocument,
  IdaxDeliveriesReportDownloadQuery,
  IdaxDeliveriesReportQuery,
  IdaxDeliveriesReportQueryVariables,
  Reports,
  SortDirection
} from "$typings/graphql-codegen";
import {useCaseInsensitiveTranslation} from "$lib/hooks/case-insensitive-translation";
import {ColumnDefinition} from "$pages/reports-react/column-definition";
import {SortObject, useGenericReport} from "$pages/reports-react/generic-report";
import {ReportGridDataHook} from "$pages/reports-react/generic-report-hooks";
import {formatDate} from "$lib/dateHelpers";
import {ColumnWidth} from "$pages/reports-react/ColumnWidth";

enum ColumnKey {
  Buid = 'buid',
  BuidId = 'buidId',
  CompanyName = 'companyName',
  ReceivingPlace = 'receivingPlace',
  AmountIda11 = 'amountIda11',
  AmountIda211 = 'amountIda211',
  AmountTotal = 'amountTotal',
}

type ReportResult = IdaxDeliveriesReportQuery
type ReportVariables = IdaxDeliveriesReportQueryVariables
type ReportData = IdaxDeliveriesReportQuery["reports"]["idaDeliveries"]["data"][0]
type ReportDownloadResult = IdaxDeliveriesReportDownloadQuery

const IdaxDeliveriesReport: FC<HTMLAttributes<HTMLElement>> = () => {
  const [t] = useCaseInsensitiveTranslation()
  const Title: string = t('UI_Reports_Ida_Deliveries')
  const Filename: string = 'idax-deliveries-report'
  
  const DefaultSortSettings: SortObject =  {
    sortColumnKey: ColumnKey.AmountTotal,
    sortDirection: SortDirection.Desc
  }
  
  const ColumnDefinitions: ColumnDefinition<ReportData>[] = [
    new ColumnDefinition<ReportData>(
      t('IDA-11'),
      (row: ReportData) => String(row[ColumnKey.AmountIda11]),
      ColumnKey.AmountIda11,
      ColumnWidth.Generic.Shorter
    ),
    new ColumnDefinition<ReportData>(
      t('IDA-211'),
      (row: ReportData) => String(row[ColumnKey.AmountIda211]),
      ColumnKey.AmountIda211,
      ColumnWidth.Generic.Shorter
    ),
    new ColumnDefinition<ReportData>(
      t('ui_reports_ida_deliveries_total_amount_of_devices'),
      (row: ReportData) => String(row[ColumnKey.AmountTotal]),
      ColumnKey.AmountTotal,
      ColumnWidth.Generic.Short
    ),
    new ColumnDefinition<ReportData>(
      t('BUID'),
      (row: ReportData) => String(row[ColumnKey.Buid]),
      ColumnKey.Buid,
      ColumnWidth.Common.Buid
    ),
    new ColumnDefinition<ReportData>(
      t('ui_reports_ida_deliveries_company_name'),
      (row: ReportData) => String(row[ColumnKey.CompanyName]),
      ColumnKey.CompanyName,
      ColumnWidth.Generic.Medium
    ),
    new ColumnDefinition<ReportData>(
      t('ui_reports_ida_deliveries_receiving_place'),
      (row: ReportData) => String(row[ColumnKey.ReceivingPlace]),
      ColumnKey.ReceivingPlace,
      ColumnWidth.Generic.Long
    ),
  ]
  
  const { GenericReport } = useGenericReport<
    ReportResult,
    ReportVariables,
    ReportData,
    ReportDownloadResult
  >()
  
  function createTitle(data: ReportGridDataHook<ReportData>, variables?: ReportVariables): JSX.Element {
    const title: JSX.Element = <div>{ Title }</div>
    let subTitle: JSX.Element | null = null
    if (variables?.input) {
      const n: number = data.data?.length ?? 0
      const CastDate = (v: Date | string | number | null | undefined) => new Date(v ?? '')
      const fmt = (v: Date) => formatDate(v, false, '.')
      const fromDate: Date = (CastDate)(variables?.input?.fromDate)
      const toDate: Date = (CastDate)(variables?.input?.toDate)
      const from: string = fmt(fromDate)
      const to: string = fmt(toDate)
      subTitle = <div>{ `Period: ${from} - ${to} (${n} items)` }</div>
    }
    const displaySubTitle: boolean | undefined = variables?.input && !data.isLoading
    return (
      <div className="flex flex-col gap-4">
        { title }
        { displaySubTitle && subTitle }
      </div>
    )
  }
  
  function dataSelector(result: ReportResult): ReportData[] {
    return result?.reports?.idaDeliveries?.data as ReportData[] ?? []
  }
  
  function dataCounter(result: ReportResult): number {
    return dataSelector(result).length
  }
  
  function downloadUrlSelector(result: ReportDownloadResult): string {
    return result?.reports?.idaDeliveries?.blobUrl ?? ''
  }
  
  return <GenericReport
    titleCallback={createTitle}
    reportEnum={Reports.IdaDeliveries}
    mainQueryDocument={IdaxDeliveriesReportDocument}
    downloadQueryDocument={IdaxDeliveriesReportDownloadDocument}
    columnDefinitions={ColumnDefinitions}
    dataSelector={dataSelector}
    dataCounter={dataCounter}
    defaultSortSettings={DefaultSortSettings}
    downloadUrlSelector={downloadUrlSelector}
    downloadFilename={Filename} />
}

export default IdaxDeliveriesReport
