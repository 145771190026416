import {AureliaReactReportWrapper} from "$pages/reports-react/AureliaReactReportWrapper";
import IdaxDeliveriesReport from "$pages/reports-react/idax-deliveries-report/idax-deliveries-report";

class IdaxDeliveriesReportReactWrapper extends AureliaReactReportWrapper {
  constructor() {
    super(IdaxDeliveriesReport);
  }
}

export default IdaxDeliveriesReportReactWrapper
